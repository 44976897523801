import React from 'react'
import { graphql } from 'gatsby'
//content
import Seo from '../components/SEO'
import RichContent from '../components/content/RichContent'
//scss
import '../scss/page/blogPost.scss'
const TemplateBlogPost = ({ data, pageContext, location }) => {
  const { content } = data.c

  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />

      <div className='blogPost'>
        <div className='container'>
          <div className='content'>
            <h1>{data.c.title}</h1>
            <RichContent myRichContent={content} />
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    c: contentfulBlog(id: { eq: $id }) {
      title
      content {
        raw
      }
    }
  }
`

export default TemplateBlogPost
